import React, { useState } from "react";
import { useIntl, IntlContextConsumer, changeLocale } from "gatsby-plugin-intl";
import CloseIcon from "assets/images/svgs/close.svg";
import MenuIcon from "assets/images/svgs/menu.svg";
import Logo from "assets/images/svgs/logo.svg";
import LanguageIcon from "assets/images/globe.png";
import * as S from "./NavbarMobile.styles";

const languageKeys = {
  en: "navbar.english",
  pt: "navbar.portuguese",
};

const NavbarMobile = () => {
  const { formatMessage } = useIntl();
  const [isOpen, setIsOpen] = useState();
  const [showOptions, setShowOptions] = useState(false);

  const handleClose = () => {
    setIsOpen(false);
    setShowOptions(false);
  };

  const handleOpen = () => setIsOpen(true);

  return (
    <>
      <S.Wrapper>
        <S.LogoWrapper href="#vps">
          <Logo aria-label="TM1" />
        </S.LogoWrapper>
        <S.OpenMenu onClick={handleOpen}>
          <MenuIcon />
        </S.OpenMenu>
      </S.Wrapper>
      <S.Menu isOpen={isOpen}>
        <S.LogoWrapper>
          <Logo aria-label="TM1" />
        </S.LogoWrapper>
        <S.CloseButton onClick={handleClose}>
          <CloseIcon />
        </S.CloseButton>
        <S.MenuNav>
          <S.MenuLink href="#aboutUs">
            <span onClick={handleClose}>
              {formatMessage({ id: "navbar.aboutUs" })}
            </span>
          </S.MenuLink>
          <S.MenuLink href="#whatWeDo">
            <span onClick={handleClose}>
              {formatMessage({ id: "navbar.whatWeDo" })}
            </span>
          </S.MenuLink>
          <S.MenuLink href="#clients">
            <span onClick={handleClose}>
              {formatMessage({ id: "navbar.clients" })}
            </span>
          </S.MenuLink>
          <S.MenuLink onClick={handleClose} href="#cases">
            {formatMessage({ id: "navbar.cases" })}
          </S.MenuLink>
          <S.MenuLink href="/live-streaming">
            <span onClick={handleClose}>
              {formatMessage({ id: "navbar.liveStreaming" })}
            </span>
          </S.MenuLink>
          <S.MenuLink href="https://tm1.gupy.io/" target="_blank">
            {formatMessage({ id: "navbar.work" })}
          </S.MenuLink>
          <S.MenuLink onClick={handleClose} href="#contact">
            {formatMessage({ id: "navbar.contact" })}
          </S.MenuLink>
          <S.MenuLink role="button">
            <IntlContextConsumer>
              {({ languages, language: currentLanguage }) =>
                languages.map((language) => {
                  if (language != currentLanguage) {
                    return (
                      <S.LanguageOption
                        key={language}
                        onClick={() => {
                          changeLocale(language);
                          handleClose();
                        }}
                      >
                        <S.LanguageIcon src={LanguageIcon} />
                        {formatMessage({ id: "navbar.changeLanguage" })}
                      </S.LanguageOption>
                    );
                  }
                })
              }
            </IntlContextConsumer>
          </S.MenuLink>
        </S.MenuNav>
        <S.Space />
      </S.Menu>
    </>
  );
};

export default NavbarMobile;
