import React from "react";
import FacebookLogo from "assets/images/svgs/facebook.svg";
import TiktokLogo from "assets/images/svgs/tiktok.svg";

import InstagramLogo from "assets/images/svgs/instagram.svg";
import LinkedinLogo from "assets/images/svgs/linkedin.svg";
import YoutubeLogo from "assets/images/svgs/youtube.svg";
import * as S from "./Contact.styles";
import { useIntl } from "gatsby-plugin-intl";
import policyPT from "assets/docs/TM1-Politica-de-Privacidade-e-Protecao-de-Dados-Pessoais.pdf";
import policyEN from "assets/docs/TM1-Politica-de-Privacidade-e-Protecao-de-Dados-Pessoais.pdf";
import cookiesPT from "assets/docs/TM1-Politica-de-Cookies-Site.pdf";
import cookiesEN from "assets/docs/TM1-Politica-de-Cookies-Site.pdf";

const Footer = () => {
  const { formatMessage, locale } = useIntl();

  const policyLanguage = {
    pt: policyPT,
    en: policyEN,
  };

  const cookiesLanguage = {
    pt: cookiesPT,
    en: cookiesEN,
  };

  return (
    <S.Container id="contact">
      <S.Line />
      <S.SubTitle>{formatMessage({ id: "contact.title" })}</S.SubTitle>
      <S.Wrapper>
        <S.Group>
          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-1.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a href={formatMessage({ id: "contact.group-1.link" })}>
              {formatMessage({ id: "contact.group-1.description" })}
            </a>
          </S.Paragraph>
          <S.ParagraphTitle>
            <a
              target="_blank"
              href={formatMessage({ id: "contact.group-2.link" })}
            >
              {formatMessage({ id: "contact.group-2.title" })}
            </a>
          </S.ParagraphTitle>
          <S.Paragraph>
            <a href={formatMessage({ id: "contact.group-2.link" })}>
              {formatMessage({ id: "contact.group-2.description" })}
            </a>

            <a target="_blank" href="https://tm1.gupy.io">
              tm1.gupy.io
              {/* {formatMessage({ id: "group-11.description" })} */}
            </a>
          </S.Paragraph>
          {/* 
          <S.ParagraphTitle>
            Gupy
            {formatMessage({ id: "contact.group-13.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a target="_blank" href="https://tm1.gupy.io">
              https://tm1.gupy.io
              {formatMessage({ id: "group-11.description" })}
            </a>
          </S.Paragraph> */}
          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-3.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a href={formatMessage({ id: "contact.group-3.link" })}>
              {formatMessage({ id: "contact.group-3.description" })}
            </a>
          </S.Paragraph>

          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-15.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-15.link" })}
            >
              {formatMessage({ id: "contact.group-15.description" })}
            </a>
          </S.Paragraph>
          

          {/* <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-15-01.link" })}
            >
              {formatMessage({ id: "contact.group-15-01.description" })}
            </a>
          </S.Paragraph> */}

          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-15-02.link" })}
            >
              {formatMessage({ id: "contact.group-15-02.description" })}
            </a>
          </S.Paragraph>

        </S.Group>
        <S.Group>
          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-4.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a href={formatMessage({ id: "contact.group-4.link" })}>
              {formatMessage({ id: "contact.group-4.description" })}
            </a>
          </S.Paragraph>
          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-5.title" })}
          </S.ParagraphTitle>

          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-5.link" })}
            >
              {formatMessage({ id: "contact.group-5.description" })}
            </a>
          </S.Paragraph>

          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-14.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-14.link" })}
            >
              {formatMessage({ id: "contact.group-14.description" })}
            </a>
          </S.Paragraph>

          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-16.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-16.link" })}
            >
              {formatMessage({ id: "contact.group-16.description" })}
            </a>
          </S.Paragraph>

          {/* <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-10.title" })}
          </S.ParagraphTitle>
          <S.Paragraph>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={formatMessage({ id: "contact.group-10.link" })}
            >
              {formatMessage({ id: "contact.group-10.description" })}
            </a>
          </S.Paragraph> */}
        </S.Group>
        <S.Group>
          <S.ParagraphTitle>
            {formatMessage({ id: "contact.group-6.title" })}
          </S.ParagraphTitle>
          <S.ParagraphSecondTitle>
            {formatMessage({ id: "tm1 Brand Experience" })}
          </S.ParagraphSecondTitle>
          <S.Paragraph>
            {formatMessage({
              id: "Av. Divino Salvador, 319 São Paulo - SP, 04078-011",
            })}
          </S.Paragraph>
          <S.ParagraphSecondTitle>
            {formatMessage({ id: "tm1 Brand Experience USA" })}
          </S.ParagraphSecondTitle>
          <S.Paragraph>
            {formatMessage({
              id: "7901 Kingspointe Parkway, Orlando, FL 32819",
            })}
          </S.Paragraph>
          {/* <S.ParagraphTitle>
            <S.ButtonInfo target="_blank" href={policyLanguage[locale]}>
              {formatMessage({ id: "contact.group-7.title" })}
            </S.ButtonInfo>
            <S.Paragraph></S.Paragraph>
          </S.ParagraphTitle>
          <S.ParagraphTitle>
            <S.ButtonInfo target="_blank" href={cookiesLanguage[locale]}>
              {formatMessage({ id: "contact.group-8.title" })}
            </S.ButtonInfo>
            <S.Paragraph></S.Paragraph>
          </S.ParagraphTitle> */}
        </S.Group>
        <S.Group>
          <S.ParagraphTitleNetworks>
            {formatMessage({ id: "contact.group-9.title" })}
          </S.ParagraphTitleNetworks>
          <S.GroupNetworks>
            {/* <S.IconContainer>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatMessage({ id: "contact.group-9.link-1" })}
              >
                <FacebookLogo />
              </a>
            </S.IconContainer> */}
            <S.IconContainer>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatMessage({ id: "contact.group-9.link-2" })}
              >
                <InstagramLogo />
              </a>
            </S.IconContainer>
            <S.IconContainer>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatMessage({ id: "contact.group-9.link-3" })}
              >
                <LinkedinLogo />
              </a>
            </S.IconContainer>
            <S.IconContainer>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatMessage({ id: "contact.group-9.link-4" })}
              >
                <YoutubeLogo />
              </a>
            </S.IconContainer>

            <S.IconContainer>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={formatMessage({ id: "contact.group-9.link-1" })}
              >
                <TiktokLogo />
              </a>
            </S.IconContainer>
          </S.GroupNetworks>
          <S.Linkme>
            <a href="https://linkme.bio/tm1.br/" target="__blank">
              linkme.bio
            </a>
          </S.Linkme>
        </S.Group>
      </S.Wrapper>
    </S.Container>
  );
};

export default Footer;
